<template>
  <router-view />
</template>
<script>
export default {
  name: 'ToBank',
  created () {
    this.$store.commit('setBreadcrumbItems', [
        { text: 'Home', routeName: 'Home' },
        { text: 'Balance', routeName: 'TripwePay' }, 
        { text: 'Transfer', routeName: 'Transfer' },
        { text: 'Transfer Bank', routeName: 'ToBank' }
    ]);
  },
}
</script>